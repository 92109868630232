import React from "react";
import Section from "../../../../components/Section";
// import ConnectedBrands from "./components/ConnectedBrands";
import LaunchedNotification from "./components/LaunchedNotification";
import Description from "./components/Description";

const Hero = () => {
  return (
    <Section containerClassName="text-center lg:py-8 lg:px-12">
      <LaunchedNotification />
      <Description />
      {/* <ConnectedBrands /> */}
    </Section>
  );
};

export default Hero;
