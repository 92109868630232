import React from "react";
import SocialIcon from "./components/SocialIcon";
import FooterLink from "./components/FooterLink";
import Icon, { IconName } from "../Icons/";

const Footer = () => {
  return (
    <footer className="text-white p-4 bg-gray-900 sm:p-6">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a
              href="https://wardrobestylistgpt.fashion"
              className="flex items-center"
            >
              <span className="self-center text-2xl font-semibold whitespace-nowrap">
                Wardrobe Stylist GPT
              </span>
            </a>
            <div>
              <span className="text-sm text-gray-400">
                Made with ❤️ by PrimeFinder.ai
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-200 uppercase">
                Resources
              </h2>
              <ul className="">
                <FooterLink
                  link="https://forms.gle/iNZH12osy7R1ekbF9"
                  name="Suggest features"
                />
                <FooterLink link="https://blogs.primefinder.ai/" name="Blogs" />
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-200 uppercase">
                Follow us
              </h2>
              <ul className="">
                <FooterLink link="https://x.com/primefinder_ai" name="X" />
                <FooterLink
                  link="https://www.instagram.com/primefinder.ai/"
                  name="Instagram"
                />
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-200 uppercase">
                Legal
              </h2>
              <ul className="">
                <FooterLink link="#tnc" name="Terms &amp; Conditions" />
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center ">
            © {new Date().getFullYear()}{" "}
            <a
              href="https://wardrobestylistgpt.fashion"
              className="hover:underline"
            >
              Wardrobe Stylist GPT
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <SocialIcon
              link="https://x.com/primefinder_ai"
              icon={<Icon className="w-5 h-5" name={IconName.XCompany} />}
            />
            <SocialIcon
              link="https://www.instagram.com/primefinder.ai"
              icon={<Icon className="w-5 h-5" name={IconName.Instagram} />}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
