import React from "react";
import { IconName, iconRegistry } from "./registry";

type HTMLProps = Omit<React.HTMLAttributes<SVGSVGElement>, "name">;

interface IconProps extends HTMLProps {
  name: IconName;
}

const Icon = ({ name, ...rest }: IconProps) => {
  const Svg = iconRegistry[name];

  return <Svg {...rest} />;
};

export default Icon;
