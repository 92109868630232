import React from "react";
import Section from "../../../../components/Section";

const Support = () => {
  return (
    <Section id="support">
      <h2 className="mb-4 text-2xl tracking-tight font-bold text-gray-900">
        Support Our Free Tool: Buy Us a Coffee!
      </h2>
      <div className="mb-4">
        <p className="text-gray-500 dark:text-gray-400">
          Enjoy using our product for free! If you find it helpful and want to
          support its continued development, consider buying us a coffee. Your
          contribution helps keep us going! Click the link below to show your
          support.
        </p>
      </div>
      <div className="pt-8 text-left border-t border-gray-200">
        <a href="https://www.buymeacoffee.com/delkhoshsiv">
          <img
            alt="buymecoffee"
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=delkhoshsiv&button_colour=f76a9c&font_colour=ffffff&font_family=Comic&outline_colour=000000&coffee_colour=FFDD00"
          />
        </a>
      </div>
    </Section>
  );
};

export default Support;
