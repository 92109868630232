import React from "react";

interface SocialIconProps {
  link: string;
  icon: React.ReactNode;
}

const SocialIcon = ({ link, icon }: SocialIconProps) => {
  return (
    <a
      href={link}
      className="text-gray-500 hover:text-gray-300 dark:hover:text-white"
    >
      {icon}
    </a>
  );
};

export default SocialIcon;
