import React from "react";
import * as Svg from "./svgs";

export enum IconName {
  Amazon = "Amazon",
  ArrowRight = "ArrowRight",
  Approved = "Approved",
  Brain = "Brain",
  Search = "Search",
  XCompany = "XCompany",
  Instagram = "Instagram",
  Information = "Information",
  Dollar = "Dollar",
  FaceLaugh = "FaceLaugh",
  Puzzle = "Puzzle",
  Globe = "Globe",
  Chart = "Chart",
  LinkChain = "LinkChain",
  OpenAi = "OpenAi",
  CircleCheck = "CircleCheck",
  CircleClose = "CircleClose",
  Copy = "Copy",
  Store = "Store",
  FilledStar = "FilledStar",
  Calendar = "Calendar",
  Camera = "Camera",
}

export const iconRegistry: Record<
  IconName,
  React.ComponentType<React.SVGAttributes<SVGSVGElement>>
> = {
  [IconName.Amazon]: Svg.Amazon,
  [IconName.ArrowRight]: Svg.ArrowRight,
  [IconName.Approved]: Svg.Approved,
  [IconName.Brain]: Svg.Brain,
  [IconName.Search]: Svg.Search,
  [IconName.XCompany]: Svg.XCompany,
  [IconName.Instagram]: Svg.Instagram,
  [IconName.Information]: Svg.Information,
  [IconName.Dollar]: Svg.Dollar,
  [IconName.FaceLaugh]: Svg.FaceLaugh,
  [IconName.Puzzle]: Svg.Puzzle,
  [IconName.Globe]: Svg.Globe,
  [IconName.Chart]: Svg.Chart,
  [IconName.LinkChain]: Svg.LinkChain,
  [IconName.OpenAi]: Svg.OpenAi,
  [IconName.CircleCheck]: Svg.CircleCheck,
  [IconName.CircleClose]: Svg.CircleClose,
  [IconName.Copy]: Svg.Copy,
  [IconName.Store]: Svg.Store,
  [IconName.FilledStar]: Svg.FilledStar,
  [IconName.Calendar]: Svg.Calendar,
  [IconName.Camera]: Svg.Camera,
};
