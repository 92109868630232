import React, { Fragment } from "react";

interface NavLinkProps {
  path: string;
  linkName: string;
}

const NavLink = ({ path, linkName }: NavLinkProps) => {
  return (
    <Fragment>
      <a className="hover:text-primary-50" href={path}>
        {linkName}
      </a>
    </Fragment>
  );
};

export default NavLink;
