import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Hero from "./components/Hero";
import Features from "./components/Features";
import FAQ from "./components/FAQ";
import Tnc from "./components/Tnc";
import Support from "./components/Support";
import SuggestFeature from "./components/SuggestFeature";

const HomePage = () => {
  return (
    <div className="flex flex-col w-full">
      <ScrollToTop />
      <Hero />
      <Features />
      <FAQ />
      <Tnc />
      <SuggestFeature />
      <Support />
    </div>
  );
};

export default HomePage;
