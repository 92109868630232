import React from "react";
import Question from "./components/Question";
import Section from "../../../../components/Section";

const FAQ = () => {
  return (
    <Section id="faq">
      <h2 className="mb-8 text-2xl tracking-tight font-bold text-gray-900">
        Frequently Asked Questions
      </h2>
      <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
        <div>
          <Question
            question={`What is Wardrobe Stylist GPT?`}
            answer="A virtual stylist that analyzes wardrobes and suggests outfits and fashion items."
          />
          <Question
            question={`Is Wardrobe Stylist GPT free?`}
            answer="Yes, it's completely free to use! Enjoy personalized style recommendations without any cost or hidden fees."
          />
        </div>
        <div>
          <Question
            question={`How can I use Wardrobe stylist GPT?`}
            answer="Head over to ChatGPT and search for 'Wardrobe Stylist GPT' or simply click this link to get started."
          />
          <Question
            question={`How does Wardrobe Stylist GPT work?`}
            answer="Wardrobe Stylist GPT analyzes the items in your wardrobe and provides personalized outfit suggestions. It also recommends new fashion items that complement your existing collection."
          />
        </div>
      </div>
    </Section>
  );
};

export default FAQ;
