import React from "react";

const Description = () => {
  return (
    <div>
      <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
        A virtual stylist that analyzes wardrobes and suggests outfits and
        fashion items.
      </h1>
      <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
        Wardrobe Stylist GPT: Your personal virtual stylist that analyzes your
        wardrobe to suggest tailored outfits and fashion items. Elevate your
        style effortlessly.
      </p>
      <div className="flex flex-col space-y-2 mb-3 sm:flex-row sm:justify-center md:space-x-2 md:space-y-0">
        <a
          href="https://chatgpt.com/g/g-CA1VEsDK0-wardrobe-stylist"
          className="outline inline-flex outline-primary-50 justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-50 hover:bg-primary-100 focus:ring-4"
        >
          Wardrobe Stylist GPT
        </a>
      </div>
    </div>
  );
};

export default Description;
