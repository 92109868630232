import React from "react";
import Section from "../../../../components/Section";
import Feature from "./components/Feature";
import Icon, { IconName } from "../../../../components/Icons";

const Features = () => {
  return (
    <Section id="features" containerClassName="space-y-10">
      <div className="space-y-10">
        <div className="max-w-screen-md">
          <h2 className="mb-2 text-2xl tracking-tight font-bold text-gray-900">
            Unlock the Power of Wardrobe Stylist GPT
          </h2>
          <p className="text-gray-500 sm:text-xl">
            Wardrobe Stylist GPT is your personal fashion assistant, offering
            trend updates, outfit suggestions, and shopping tips. Elevate your
            style effortlessly with AI-powered guidance tailored just for you.
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 md:grid-cols-3 md:gap-12 md:space-y-0 pb-8">
          <Feature
            title="Discover the Latest Trends"
            description="Stay ahead of the fashion game with real-time updates on the newest trends. Our stylist keeps you informed about what's hot, so you're always in style."
            icon={
              <Icon
                className="w-5 h-5 text-white lg:w-6 lg:h-6"
                name={IconName.FilledStar}
              />
            }
          />
          <Feature
            title="Wardrobe-Driven Suggestions"
            description="Upload your wardrobe images, and let the stylist recommend outfits from what you already own. Maximize your closet with fresh, personalized ideas."
            icon={
              <Icon
                className="w-5 h-5 text-white lg:w-6 lg:h-6"
                name={IconName.Camera}
              />
            }
          />
          <Feature
            title="Tailored to Your Events"
            description="Get outfit suggestions based on the time, occasion, and location you're attending. Whether it's casual brunch or a formal evening, we'll find the perfect look for you."
            icon={
              <Icon
                className="w-5 h-5 text-white lg:w-6 lg:h-6"
                name={IconName.Calendar}
              />
            }
          />
          <Feature
            title="Shop the Trends"
            description="Seamlessly browse and purchase trending clothes, handpicked for your style. We’ll guide you to the latest must-have pieces that match your fashion goals."
            icon={
              <Icon
                className="w-5 h-5 text-white lg:w-6 lg:h-6"
                name={IconName.Store}
              />
            }
          />
          <Feature
            title="Effortless Style Planning"
            description="Let our stylist curate outfits for your entire week or special events, taking the hassle out of what to wear and making fashion effortless."
            icon={
              <Icon
                className="w-5 h-5 text-white lg:w-6 lg:h-6"
                name={IconName.FaceLaugh}
              />
            }
          />
          <Feature
            title="Smart Suggestions for Every Season"
            description="Whether it’s summer or winter, get climate-appropriate fashion advice that keeps you comfortable and stylish no matter the season."
            icon={
              <Icon
                className="w-5 h-5 text-white lg:w-6 lg:h-6"
                name={IconName.Brain}
              />
            }
          />
        </div>
      </div>
    </Section>
  );
};

export default Features;
