import React from "react";
import { Link } from "react-router-dom";

interface LinkProps {
  name: string;
  link: string;
}

const FooterLink = ({ name, link }: LinkProps) => {
  return (
    <li className="mb-4">
      <Link
        to={link}
        className="text-gray-400 hover:underline hover:text-gray-100"
      >
        {name}
      </Link>
    </li>
  );
};

export default FooterLink;
