import React from "react";
import Section from "../../../../components/Section";

const SuggestFeature = () => {
  return (
    <Section id="suggest-features">
      <h2 className="mb-8 text-2xl tracking-tight font-bold text-gray-900">
        Help us add features you want
      </h2>
      <div className="mb-4">
        <p className="text-gray-500 dark:text-gray-400">
          Have a feature in mind? We’d love to hear your ideas! Help us improve
          by suggesting new features through the form below. Your input helps us
          make the product even better for everyone.
        </p>
      </div>
      <div className="pt-4 text-left border-t border-gray-200">
        <iframe
          className="m-auto w-full"
          height="800px"
          title="Suggest improvements"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdXxLpga-2uD7df03KthFHCjrkn-uyrT791RByjYSCLnf4j4g/viewform?embedded=true"
        >
          Loading…
        </iframe>
      </div>
    </Section>
  );
};

export default SuggestFeature;
