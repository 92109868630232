import React from "react";
import NavLink from "./components/NavLink";
import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Navbar className="text-white bg-gray-900">
      <Navbar.Brand as={Link} href="https://wardrobestylistgpt.fashion/">
        <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
          Wardrobe Stylist GPT
        </span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <ul className="flex flex-col font-medium p-4 md:p-0 border border-gray-100 md:space-x-8 rtl:space-x-reverse md:flex-row md:border-0">
          <li>
            <NavLink linkName="Features" path="#features" />
          </li>
          <li>
            <NavLink linkName="Support Us" path="#support" />
          </li>
          <li>
            <NavLink linkName="Suggest Feature" path="#suggest-features" />
          </li>
          <li>
            <NavLink linkName="FAQ" path="#faq" />
          </li>
          <li>
            <NavLink linkName="Terms &amp; Conditions" path="#tnc" />
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
