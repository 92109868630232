import React from "react";
import Icon from "../../../../../../components/Icons/Icon";
import { IconName } from "../../../../../../components/Icons";

const LaunchedNotification = () => {
  return (
    <a
      href="https://chatgpt.com/g/g-CA1VEsDK0-wardrobe-stylist"
      className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
      role="alert"
    >
      <span className="text-xs bg-primary-50 rounded-full text-white px-4 py-1.5 mr-3">
        New
      </span>{" "}
      <span className="text-sm font-medium">
        "Wardrobe Stylist GPT" is launched
      </span>
      <Icon className="ml-2 w-5 h-5" name={IconName.ArrowRight} />
    </a>
  );
};

export default LaunchedNotification;
