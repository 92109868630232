import React from "react";
import Section from "../../../../components/Section";

interface TncContentProps {
  rule: string;
  content: string;
}

const TncContent = ({ rule, content }: TncContentProps) => {
  return (
    <div>
      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
        {rule}
      </h3>
      <p className="text-gray-500 dark:text-gray-400">{content}</p>
    </div>
  );
};

const Tnc = () => {
  return (
    <Section id="tnc">
      <h2 className="mb-4 text-2xl tracking-tight font-bold text-gray-900">
        Terms &amp; Conditions
      </h2>
      <div className="mb-4">
        <p className="text-gray-500 dark:text-gray-400">
          By using Wardrobe Stylist GPT, you acknowledge that you have read,
          understood, and agree to these terms and conditions.
        </p>
      </div>
      <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
        <TncContent
          rule="Service Overview"
          content="Wardrobe Stylist GPT provides fashion advice, outfit
          suggestions, and product recommendations based on the wardrobe
          information you provide. The service is for personal use only."
        />

        <TncContent
          rule="User Data Privacy"
          content="We respect your privacy. Any wardrobe or personal
          data you share with us is stored securely and is not shared with third
          parties without your consent."
        />

        <TncContent
          rule="Accuracy of Suggestions"
          content="While we strive to provide the most accurate
          and personalized fashion advice, all suggestions are for informational
          purposes only. Wardrobe Stylist GPT does not guarantee that the
          recommendations will meet your personal style preferences or fit
          perfectly."
        />

        <TncContent
          rule="User Responsibility"
          content="You are responsible for the accuracy of the
          wardrobe information you provide. Any product purchases made based on
          our recommendations are made at your own discretion."
        />

        <TncContent
          rule="Third-Party Links"
          content="Wardrobe Stylist GPT may recommend products from
          third-party retailers. We are not responsible for the availability,
          quality, or pricing of items from third-party websites."
        />

        <TncContent
          rule="Free Service"
          content="Our service is currently free to use. We reserve the
          right to introduce paid features or subscriptions in the future, but
          we will notify users in advance of any changes."
        />

        <TncContent
          rule="Changes to Terms"
          content="We reserve the right to modify these terms at any
          time. Continued use of the service after any changes are made
          constitutes acceptance of the new terms."
        />

        <TncContent
          rule="Termination"
          content=" We reserve the right to suspend or terminate access to
          Wardrobe Stylist GPT for users who violate these terms or misuse the
          service."
        />
      </div>
    </Section>
  );
};

export default Tnc;
