import React from "react";

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  containerClassName?: string;
}

const Section = ({ children, containerClassName, ...rest }: SectionProps) => {
  return (
    <section className="bg-white dark:bg-gray-900" {...rest}>
      <div
        className={`pt-2 pb-6 px-4 mx-auto max-w-screen-xl lg:px-6 ${containerClassName}`}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
